import React, {useState, useEffect, useRef} from "react"
import { navigate } from "gatsby"
import {Table, Form, FormControl, Alert, Toast, InputGroup, Button, Tooltip, OverlayTrigger, Row, Col, Badge} from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo";
import RightSidebar from "../components/sidebar/rightSideBar"
import StatusDropdown from '../components/statusDropdown';
import StandardModal from '../components/modal';
import GenericPagination from '../components/genericPagination';
import ChangeLog from '../components/changeLog';
import Loader from '../components/loading/';
import GroupingFilter from '../components/filters/groupingFilter'
import {replaceSpaceInPhoneNumber} from '../helpers/string';
import formatChangeLog from '../helpers/formatChangeLog';
import constants from '../helpers/constants';
import Fetch from '../helpers/fetch';
import {DOSDDMMYYYYFormat, DDMMYYYYFormat, addDays, substractDays} from '../helpers/dateTime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const CompletedOrders = () => {
    const [prodArray, setProdArray] = useState([]);
    const [id, setId] = useState(0);
    const [status, setStatus]= useState('');
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);

    const [pagination, setPagination] = useState({});
    const [custId, setCustId] = useState('');
    const [phone, setPhone] = useState('');
    const [orderId, setOrderId] = useState('');
    const [itemId, setItemId] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showFGModal, setShowFGModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    
    const [orderItem, setOrderItem] = useState({});
    const [rowLimit, setRowLimit] = useState(25);
    const [showUrgentDateRequired, setShowUrgentDateRequired] = useState(false);
    const [showUrgentDateEditmodal, setShowUrgentDateEditmodal] = useState(false);
    const [itemInByDate, setItemInByDate] = useState(new Date())
    const [showRightSidebar, setshowRightSidebar] = useState(false)

    const [orderGroupings, setOrderGroupings] = useState([]);
    const [orderGroupingFilter, setOrderGroupingFilter] = useState('');

    
    const handleAlertClose = () => {
      setShowAlert(false);
    }

    const handleFGClose = () => {
      
      setShowFGModal(false);
    }
    
    const handleClose = () => {
      setShow(false);
      setShowUrgentDateRequired(false);
    }

    const handleUrgentDateModalClose = () => {
      setShowUrgentDateEditmodal(false)
    }

    const handleConfirm = () => {
      confirmFunction();
    }

    const handleUrgentDateModalConfirm = () => {
      confirmFunction();
      document.getElementById(`form${orderItem.id}`).classList.toggle('d-none')
      setShowUrgentDateEditmodal(false);
    }

    const handleFGConfirm =() => {
      const {
        id, 
        updated_on: updatedOn, 
        item_type: itemType, 
        production_type: productionType,
        urgent_date: urgentDate,
        item_in_by: itemInBy,
        custid,
      } = orderItem;
      const status = 'ProcessingFG';
      const data = {
        id,
        updatedOn,
        itemType,
        productionType,
        status,
        sendSMS: false,
        fit_gtd: 1,
        custid,
      }
      updateOrderItemStatus(data) 
      setShowFGModal(false);
    }

    const handleConfirmAndSendSMS = () => { 
      confirmFunction(true);
    }

    const confirmFunction = (sendSMS = false) => {
      const {
        id, 
        updated_on: updatedOn, 
        item_type: itemType, 
        production_type: productionType,
        urgent_date: urgentDate,
        item_in_by: itemInBy,
      } = orderItem;
      
      const data = {
        id,
        updatedOn,
        itemType,
        productionType,
        itemInBy,
        status,
        sendSMS,
      }
      setShow(false);
      updateOrderItemStatus(data)
    }

    const drawProdSheetTable = (custId) => {
      if (custId !=='') {
        setShowLoader(true);
        let queryString = '';
        let queryParams = [];
        let url = `${constants.BACKEND_URL}/order-items/completed`;
  
        if (page) {
          queryParams.push(`page=${page}`)
        }

        if (custId) {
          queryParams.push(`custId=${custId}`)
        }
        
        if(queryParams.length > 0) {
          queryString = `?${queryParams.join('&')}`
        }
  
        Fetch.get(`${url}${queryString}`)
        .then((data) => {
            setProdArray(data.data)
            setPagination(data.pagination);
            setShowLoader(false);
            setshowRightSidebar(false);
        }).catch((e) => {
          setShowLoader(false);
          navigate('/login');
          return false;
        }) 
      } else {
        getProdSheetData()
      }
      
    }

    const updateOrderItemStatus = (data) => {
      setShowLoader(true);
      Fetch.put(`${constants.BACKEND_URL}/order-items/prodsheet`, JSON.stringify(data)).then((res) => {
        if (!res.status) {
          setShowAlert(true)
          setAlertMessage({
            msg: res.msg
          })
          setShowLoader(false);
          setshowRightSidebar(false);
          return false;
        }
        setShowToast(true);
        drawProdSheetTable(custId);
        setShowLoader(false);
      })
    }

    const statusDropdown = (item) => {
      return (
        <div>
          <StatusDropdown 
            item={item} 
            handleStatusDropdownChange={handleStatusDropdownChange}
          >
          </StatusDropdown>
        </div>
      )
    }

    const handleStatusDropdownChange = (e) => {
      if (e.target.value !== '') {
        setShow(true);
        setId(e.target.dataset.id)
        setStatus(e.target.value);
        setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
        resetDropdown(e)
      }
    }

    const changeLog = (log) => {
      return (
        <div>
          <ChangeLog log={log}></ChangeLog>
        </div>
      )
    }

    const getItemInByFromObject = (itemInByString) => {
      const itemInByObject = itemInByString ? JSON.parse(itemInByString) : null
      if (itemInByObject) {
        const itemInBy = itemInByObject.changedDate ? 
        new Date(itemInByObject.changedDate) : 
        new Date(itemInByObject.original);
  
        return itemInBy;
      }
    }

    const max120Field = (item) => {
      const max120 = new Date().getTime() - new Date(item.time).getTime()
      const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))

      return Difference_In_Days ? Difference_In_Days : '--';
    }

    const urgentDateField = (item) => {
      let urgentDate = item.urgent_date;
      if (!urgentDate || urgentDate === '0000-00-00') {
        return;
      }
      urgentDate = new Date(urgentDate.split('T')[0]).toLocaleString("en-SG").split(",")[0];
      return (
        <>
          {urgentDate}
        </>
      )
    }

    const ActivateFitGtdButton = (item) => {
      if (item && item.status && item.status === 'Collected') {
        const now = new Date().getTime();
        const collectedTimestamp = getCollectedDateFromChangeLog(item.status_change_log);
        const twoWeeks = 1000 * 3600 * 24 * 7 * 2;
        if (now - collectedTimestamp < twoWeeks && !item.fit_gtd && !item.refunded && item.item_type !== 'ALTER') {
          return (
            <div>
              <Button variant="outline-secondary" onClick={() => showFitGuaranteedModal(item)}>Activate Fit Gtd</Button>
            </div>
          )
        }
      }
    }

    const getCollectedDateFromChangeLog = (changeLog) => {
      const collected = JSON.parse(changeLog).filter((log) => {
        return log.status === 'Collected';
      })
      const collectedTimeStamp = new Date(collected[0].timestamp).getTime()
      return collectedTimeStamp;
    }

    const showFitGuaranteedModal = (item) => {
      setShowFGModal(true);
      setOrderItem(item);
    }

    const resetDropdown = (e) => {
      e.target.value ='';
    }

    const getGroupings = (params) => {
      Fetch.get(`${constants.BACKEND_URL}/order-groupings`)
      .then(res => {
  
        if(res.error) {
          // setShowToast(true);
          // seterrorMessage(res.error);
          return;
        }
        setOrderGroupings(res.data);
      })
    }
    
    const getProdSheetData = (limit = rowLimit) => {
      setShowLoader(true);
      let queryString = '';
      let queryParams = [];
      let url = `${constants.BACKEND_URL}/order-items/completed`;

      if (page) {
        queryParams.push(`page=${page}`)
      }
      if (custId) {
        queryParams.push(`custId=${custId}`)
      }
      if (orderId) {
        queryParams.push(`orderId=${orderId}`)
      }
      if (itemId) {
        queryParams.push(`itemId=${itemId}`)
      }
      if (phone) {
        queryParams.push(`phone=${phone}`)
      }
      if (orderGroupingFilter) {
        queryParams.push(`tag=${orderGroupingFilter}`)
      }
      
      if(queryParams.length > 0) {
        queryString = `?${queryParams.join('&')}`
      }

      Fetch.get(`${url}${queryString}`)
      .then((data) => {
          setProdArray(data.data)
          setPagination(data.pagination);
          setShowLoader(false);
          setshowRightSidebar(false);
      }).catch((e) => {
        setShowLoader(false);
        navigate('/login');
        return false;
      })
    }

    const searchCustId = (e) => {
      setCustId(e.target.value);
      setPage(1);
    }

    const searchItemId = (e) => {
      setItemId(e.target.value);
      setPage(1);
    }

    const searchPhone = (e) => {
      const sanitizedPhone = replaceSpaceInPhoneNumber(e.target.value)
      setPhone(sanitizedPhone);
      setPage(1);
    }

    useEffect(() => {
      // Client-side Runtime Data Fetching
      getGroupings()
    },[])

    useEffect(() => {
      if (custId || itemId || phone ) {
        const timeOutId = setTimeout(() => getProdSheetData(), constants.SEARCH_TIMEOUT_IN_MS);
        return () => clearTimeout(timeOutId);
      } else {
        getProdSheetData()
      }
    }, [page, custId, orderId, itemId, phone, orderGroupingFilter]);

    const getOrderItemFromProdArray = (orderItemId) => {
      if (prodArray.length > 0) {
        const orderItem = prodArray.filter(item => {
          return item.id == Number(orderItemId)
        })
        return orderItem[0];
      }
    }

    const statusLabel = (item) => {
      return (
        item.status ? (
          <div>
            {item.fully_paid && 
              (item.status === constants.STATUS_COLLECTED || item.status === constants.STATUS_END_FITTING) ? (
              <Badge pill variant="success">{item.status}</Badge>
            ) : item.status}
          </div> ) : <></>
      )
    }

    const refundedLabel = (item) => {
      return (
        item.refunded ? (
          <div style={{'opacity':'0.6','color':'red'}}>
            <Badge pill variant="info">Refunded</Badge>
          </div> ) : <></>
      )
    }

    const statusList = (item) => {
      const status = statusLabel(item);
      const refunded = refundedLabel(item);

      return (
        <>
          {status}
          {refunded}
        </>
      )
    }

    const handleOnPaginationClick = (e) => {
      let target = e.target.dataset.goTo
      if (!target) {
        target = e.target.parentNode.dataset.goTo;
      }
      switch (target) {
        case "firstPage":
          setPage(1);
          break;
        case "prevPage":
          setPage(page-1);
          break;
        case "nextPage":
          setPage(page+1);
          break;
        case "lastPage":
          setPage(pagination.totalPages);
          break;
        default:
          setPage(target);
          break;
      }
    }

    const handleOnClearAllFilter = (e) => {
      setPage(1);
      setItemId('');
      setCustId('')
      setOrderId('');
      setPhone('');
      setOrderGroupingFilter('');
    }

    const handleOnFilterPillClick = (e) => {
      const filter = e.target.dataset.filter
      switch (filter) {
        case 'custId':
          setCustId('')
          break;
        case 'orderId':
          setOrderId('')
          break;
        case 'itemId':
          setItemId('')
          break;
        case 'phone':
          setPhone('')
          break;
        case 'orderGroupingFilter':
          setOrderGroupingFilter('')
          break;
        default:
          break;
      }
    }

    const toggleRightSidebar = (e) => {
      setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
      if (Number(e.target.dataset.custId) !== Number(orderItem.custid)) {
        setshowRightSidebar(!showRightSidebar)
        setshowRightSidebar(true);
      } else {
        setshowRightSidebar(!showRightSidebar)
      }
    }
    
    const handleOnSidebarClose = () => {
      setshowRightSidebar(false);
    }

    const handleOnGroupingChange = (e) => {
      setOrderGroupingFilter(e.target.value);
      setPage(1);
    }

    const tableBody = () => {
      let tempId = 0;
      let whiteColor = true;
      return prodArray.map((item, index) => {
        if (index !== 0) {
          if (tempId !== item.custid) whiteColor = !whiteColor;
        }
        const rowBg = whiteColor ? 'odd' : 'even';
        const result = (
          <tr key={item.id} className={`${rowBg}`}>
            <td>
              <span className="pointer" data-cust-id={item.custid} data-id={item.id} onClick={toggleRightSidebar}>{item.custid}</span>
            </td>
            <td>{item.id}</td>
            <td>{item.item_desc}</td>
            <td>{item.item_type}</td>
            <td>{statusList(item)}</td>
            <td>{statusDropdown(item)}{ActivateFitGtdButton(item)}</td>
            <td>{changeLog(item.status_change_log ? formatChangeLog(item.status_change_log) : '')}</td>
            <td>{item.remarks}</td>
          </tr>
        )
        tempId = item.custid;
        return result;
      })
    }
    

    return (
      <Layout pageInfo={{ pageName: "completedOrders" }}>
        <Loader show={showLoader}></Loader>
        <SEO title="Completed Orders" />
        <Row>
          <Col className="text-center mb-2">
            <h5>Completed Orders</h5>
            <h6><small>- Showing Completed/ Available for Fit Gtd orders -</small></h6>
          </Col>
        </Row>
        <Row>
          <GroupingFilter
            show={true}
            orderGroupingFilter={orderGroupingFilter}
            handleOnGroupingChange={handleOnGroupingChange}
            orderGroupings={orderGroupings}
            withLabels
          ></GroupingFilter>
          <Col>
            <Form.Group as={Row} className="mr-2 mb-2">
              <Form.Label column sm={3}>Cust ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={custId}               
                  onChange={searchCustId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} className="mr-2 mb-2">
              <Form.Label column sm={3}>Item ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={itemId}               
                  onChange={searchItemId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row}>
              <Form.Label  column sm={3}>Cust HP</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={phone}               
                  onChange={searchPhone}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
            <span onClick={handleOnFilterPillClick}>
              {orderGroupingFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Group: {orderGroupingFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="orderGroupingFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {custId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust ID: {custId} 
                  <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {itemId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Item ID: {itemId} 
                  <span className="oi oi-circle-x ml-2" data-filter="itemId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {phone && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Phone: {phone} 
                  <span className="oi oi-circle-x ml-2" data-filter="phone" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    Cust ID 
                    <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip className='text-left' id={`tooltip`}>
                            Click on Cust Id to see additional info
                          </Tooltip>
                        }
                      >
                        <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                      </OverlayTrigger>
                  </th>
                  <th>Item ID</th>
                  <th>FabID/Desc/Cont/Lin</th>
                  <th>Item</th>
                  <th>Status</th>
                  <th>Change Status</th>
                  <th>Log</th>
                  <th style={{"width":"15%"}}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {prodArray && Array.isArray(prodArray)  ? 
                  tableBody() : <></>
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        
        {pagination ? 
          <GenericPagination 
            pagination={pagination} 
            rowLimit={rowLimit}
            handleOnPaginationClick={handleOnPaginationClick}
          ></GenericPagination> : <></>}
        
        <StandardModal 
          onConfirm={handleConfirm} 
          onClose={handleClose} 
          onConfirmAndSendSMS ={handleConfirmAndSendSMS}
          status={status} 
          show={show}
          title="Changing Order Items Status"
          body={``}
        >
          <div>Order ID: <strong>{orderItem.order_id}</strong></div>
          <div>Customer ID: <strong>{orderItem.cust_id}</strong></div>
          <div>Are you sure you want to change the status of this item to <strong>{status.toUpperCase()}</strong>?</div>
        </StandardModal>

        <StandardModal 
          onClose={handleAlertClose} 
          show={showAlert}
          title="Alert Message"
          body=""
        >
          <Alert variant="danger" show={true}>
            <p>{alertMessage.msg}</p>
          </Alert>
        </StandardModal>

        <StandardModal 
          onConfirm={handleUrgentDateModalConfirm}
          onClose={handleUrgentDateModalClose} 
          show={showUrgentDateEditmodal}
          title="Changing urgent date/ item in by date "
          body={``}
        >
          <div>Order ID: <strong>{orderItem.order_id}</strong></div>
          <div>Customer ID: <strong>{orderItem.cust_id}</strong></div>
          <div>Are you sure you want to change urgent/item in by date to {DDMMYYYYFormat(getItemInByFromObject(orderItem.item_in_by))}?</div>
        </StandardModal>

        <StandardModal 
          onConfirm={handleFGConfirm}
          onClose={handleFGClose} 
          show={showFGModal}
          title="Fit Guaranteed button clicked"
          body={`Are you sure you want to Activate Fit Guarantee?`}
        >
        </StandardModal>

        <Toast 
          show={showToast} 
          onClose={() => {setShowToast(false)}}
          autohide
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Update Success</strong>
          </Toast.Header>
        </Toast>

        <RightSidebar 
          show={showRightSidebar}
          closeFn={handleOnSidebarClose}
          state={orderItem}
          >
            <h5 className="text-center mb-2">Additional Info</h5>  
            <Table>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New - Cust ID</td>
                  <td>{orderItem.custid}</td>
                </tr>
                <tr>
                  <td>Old - Cust ID</td>
                  <td>{orderItem.cust_id}</td>
                </tr>
                <tr>
                  <td>Order ID</td>
                  <td>{orderItem.order_id}</td>
                </tr>
                <tr>
                  <td>Group Name</td>
                  <td>{orderItem.group_name}</td>
                </tr>
                <tr>
                  <td>Consultant Name</td>
                  <td>{orderItem.consultant_name}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{orderItem.name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{orderItem.phone}</td>
                </tr>
                <tr>
                  <td>DOS</td>
                  <td>{DOSDDMMYYYYFormat(orderItem.time)}</td>
                </tr>
                <tr>
                  <td>Max120 (days)</td>
                  <td>{max120Field(orderItem)} </td>
                </tr>
                <tr>
                  <td>Urgent Date</td>
                  <td>{urgentDateField(orderItem)}</td>
                </tr>
              </tbody>
            </Table>
          </RightSidebar>  
        
      </Layout>
    )
}

export default CompletedOrders
